export default {
  "projectStatus": "Statut du projet : {{percentage}}%",
  "benefitsIntro": "C'est pourquoi {{name}} est le partenaire idéal :",
  "testimonials": "Ces entreprises font déjà confiance à {{name}} :",
  "readMore": "Découvrez-en plus sur {{name}}",
  "addNewSection": "Ajouter une nouvelle section",
  "implementationCountdown": "Lancement dans {{daysLeft}} jours",
  "dueDate": "Date limite :",
  "addStakeholder": "Ajouter une partie prenante",
  "projectStakeholders": "Stakeholders",
  "projectStakeholdersSubtitle": "Ici, vous pouvez gérer tous les groupes de parties prenantes qui sont pertinents pour ce projet. Vous pouvez ajouter de nouveaux groupes de parties prenantes ou des parties prenantes individuelles à tout moment",
  "addStakeholderPopoverTitle": "Ajouter un intervenant au groupe {{group}}",
  "addStakeholderPopoverSubtitle": "Les parties prenantes ne reçoivent pas d'e-mail automatique",
  "addStakeholderPopoverConfirmationText": "Je confirme que l'ajout de cette partie prenante se fait dans l'intérêt légitime du projet.",
  "addStakeholderGroup": "Ajouter un groupe d'intervenants",
  "addStakeholderGroupTitle": "Ajouter un groupe d'intervenants",
  "editStakeholderGroupTitle": "Modifier le groupe de parties prenantes",
  "deleteStakeholderGroupTooltip": "Il y a encore {{count}} parties prenantes dans ce groupe.",
  "impersonate": "Se faire passer pour",
  "name": "Nom",
  "save": "sauvegarder",
  "delete": "Supprimer",
  "approve": "Approuver",
  "approveAsGroup": "Approuver en tant que groupe",
  "approved": "Approuvé",
  "projectDocumentsTitle": "Documents",
  "projectDocumentsTextChampion": "Vous pouvez ici consulter tous les documents partagés. Pour vos collègues des départements, nous allons plus loin et n'affichons que les documents qui leur sont pertinents.",
  "projectDocumentsTextStakeholder": "Voici tous les documents que {{company}} a fournis pour vous permettre de mieux comprendre le projet et d'y donner votre accord. Prenez le temps de les consulter, posez des questions ou transmettez-les en interne.",
  "introPopoverTextChampion": "Sur cette page, vous trouverez toutes les informations pertinentes sur le projet avec {{company}}.<br><br>Fort de notre expérience, nous vous aidons à impliquer les parties prenantes de différents domaines d'expertise pour le déploiement de {{company}} et à recueillir leurs retours.",
  "introPopoverTitleChampion": "Bonjour {{name}}!",
  "introPopoverTextStakeholderSingle": "Tu as été invité par {{championName}} ({{championEmail}}) à donner ton avis sur le déploiement de {{product}}.<br><br>{{championFirstName}} a besoin de ton avis pour prendre les prochaines mesures et élaborer un planning de projet réaliste.<br><br>La page suivante est spécifiquement adaptée à ton domaine d'expertise et contient des informations sur le partenaire de projet {{company}}.",
  "introPopoverTextStakeholderMultiple": "Vous avez été invité par {{champions}} à donner votre avis sur l'introduction de {{product}}.<br><br>Ils ont besoin de votre évaluation pour initier les prochaines étapes et établir un plan de projet réaliste.<br><br>La page suivante est spécialement adaptée à votre domaine d'expertise et contient des informations sur le partenaire de projet {{company}}.",
  "introPopoverTitleStakeholder": "Bonjour {{name}}!",
  "continue": "Continuer",
  "documentTableName": "Nom",
  "documentTableUploaded": "Téléchargé",
  "documentTableActions": "Actions",
  "yourContactPersonSingle": "Votre personne de contact :",
  "yourContactPersonMultiple": "Vos personnes de contact :",
  "yourChampionSingle": "Votre champion :",
  "yourChampionMultiple": "Vos champions :",
  "requestAccessText": "Demandez l'accès à ce projet en saisissant votre adresse e-mail. Si vous êtes autorisé, nous vous enverrons un lien d'accès.",
  "requestAccessPlaceholder": "Entrer une adresse e-mail",
  "requestAccessOrgUserLink": "Connectez-vous",
  "requestAccessSuccess": "Accès demandé",
  "requestAccessOrgUser": "Veuillez vous connecter via l'accès habituel :",
  "requestAccess": "Demander l'accès",
  "email": "Courrier électronique",
  "firstName": "Prénom",
  "lastName": "Nom de famille",
  "add": "Ajouter",
  "back": "Retour",
  "send": "Envoyer",
  "documents": "Documents",
  "stakeholderApproveProjectTitle": "Prêt à approuver ce projet ?",
  "stakeholderApproveProjectText": "L'équipe projet a besoin de votre approbation pour que le projet puisse être réalisé. Une fois que vous avez terminé votre processus d'approbation, cliquez sur le bouton et faites savoir à l'équipe projet que tout est en ordre.",
  "stakeholderApproveProjectButton": "Approuver le projet",
  "stakeholderApproveProjectSuccess": "Projet approuvé ✓",
  "stakeholderInputTitleSingle": "Aide {{championFirstName}} !",
  "stakeholderInputTitleMultiple": "Aidez l'équipe de projet !",
  "stakeholderInputTextSingle": "{{championFirstName}} a besoin de ton aide et de celle de ton département pour pouvoir planifier le projet. Réponds aux questions suivantes et donne à {{championFirstName}} une estimation de ta disponibilité.",
  "stakeholderInputTextMultiple": "L'équipe projet a besoin de votre aide et de celle de votre département pour planifier le projet. Veuillez répondre aux questions suivantes et donner à l'équipe projet une estimation de votre disponibilité.",
  "stakeholderInputThankYouText": "Merci pour votre contribution ! Vous pouvez revenir à tout moment pour consulter ce que vous avez écrit.",
  "stakeholderInputFirstQuestionTitleSingle": "Quand as-tu du temps ? {{championFirstName}} collabore avec différents départements pour assurer une bonne planification du projet. Pour cela, {{championFirstName}} demande aussi ta contribution.",
  "stakeholderInputFirstQuestionTitleMultiple": "Quand as-tu du temps ? L’équipe projet collabore avec différents secteurs pour garantir une planification de projet adéquate. Pour cela, l’équipe projet demande également ton apport.",
  "stakeholderInputFirstQuestionPlaceholder": "Je n'ai pas de capacité pour les 2 prochaines semaines et je serai ensuite en vacances pendant une semaine. Après cela, ma disponibilité s'améliorera. En général, le processus prend chez nous 3 à 4 semaines, puis je pourrai donner mon accord.",
  "stakeholderInputSecondQuestionTitleSingle": "Te manque-t-il des informations ou d'autres documents de {{championFirstName}} ou de {{sellerCompany}} ? Fais-leur savoir ici.",
  "stakeholderInputSecondQuestionTitleMultiple": "Manque-t-il des informations ou d'autres documents de l'équipe projet ou de {{sellerCompany}} ? Faites-leur savoir ici.",
  "stakeholderInputSecondQuestionPlaceholder": "Je n'ai actuellement pas d'autres questions concernant ce projet.",
  "sendStakeholderLinkEmailSubject": "Votre lien d'accès",
  "sendStakeholderLinkEmailPreview": "Votre accès au projet {{sellerOrgName}}.",
  "sendStakeholderLinkEmailHeading": "Votre accès au projet <strong>{{sellerOrgName}}</strong>",
  "sendStakeholderLinkEmailHelloSentence": "Bonjour {{firstName}},",
  "sendStakeholderLinkEmailCheckoutProjectSingle": "Sous le lien suivant, tu peux consulter le projet <strong>{{sellerOrgName}}</strong> de <strong>{{championFirstName}}</strong>.",
  "sendStakeholderLinkEmailCheckoutProjectMultiple": "Sous ce lien, vous pouvez consulter le projet <strong>{{sellerOrgName}}</strong>.",
  "sendStakeholderLinkEmailFooterDisclaimer": "Cette invitation est destinée uniquement à <span className='text-black'>{{email}}</span>. Veuillez ne pas la transmettre.",
  "sendStakeholderLinkEmailOrCopyPasteUrl": "ou copiez et collez ce lien dans votre navigateur :",
  "sendStakeholderLinkEmailShowProject": "Afficher le projet",
  "sendChampionLinkEmailSubject": "Votre lien d'accès",
  "sendChampionLinkEmailPreview": "Votre accès pour le projet {{sellerOrgName}}.",
  "sendChampionLinkEmailHeading": "Votre lien de champion pour le projet <strong>{{sellerOrgName}}</strong>",
  "sendChampionLinkEmailFooterDisclaimer": "Cette invitation est destinée uniquement à <span className='text-black'>{{email}}</span>. Veuillez ne pas la transférer.",
  "sendChampionLinkEmailOrCopyPasteUrl": "ou copiez et collez ce lien dans votre navigateur :",
  "sendChampionLinkEmailShowProject": "Afficher le projet",
  "sendChampionLinkEmailHelloSentence": "Bonjour {{firstName}},",
  "sendChampionLinkEmailCheckoutProject": "Sous le lien suivant, vous pouvez consulter le projet <strong>{{sellerOrgName}}</strong>.",
  "inviteCodeExpired": "Le code d'invitation a expiré. Veuillez saisir à nouveau votre adresse e-mail.",
  "sendLink": "Envoyer le lien",
  "joinSiteDesktopExplanation": "Veuillez consulter ce site sur un ordinateur de bureau ou un écran plus grand pour en tirer le meilleur parti.",
  "joinSiteDesktopSendLink": "Entrez votre adresse e-mail et nous vous enverrons un lien vers cette page dans votre boîte de réception.",
  "requestLinkSuccess": "Lien envoyé",
  "timelineHeading": "Chronologie",
  "timelineHeadlineSubtitle": "Obtenez un aperçu des jalons et des interactions communs.",
  "timelineLogMeeting": "Enregistrer la réunion",
  "timelineFirstDemoTitle": "Première démonstration",
  "timelineFirstDemoText": "Démonstration de {{productName}} pour {{customerName}}",
  "timelineProjectPageCreatedTitle": "Page de projet créée",
  "timelineContactPersonAddedTitle": "Personne de contact <strong>{{contactPersonName}}</strong> ajoutée au groupe <strong>{{contactPersonGroupName}}</strong>",
  "timelineStakeholderAddedTitle": "Partie prenante <strong>{{stakeholderName}}</strong> ajoutée au groupe <strong>{{stakeholderGroupName}}</strong>",
  "timelineStakeholderGroupApprovalTitle": "Approbation accordée",
  "timelineStakeholderGroupApprovalTextNoApprovedBy": "<strong>{{stakeholderGroupName}}</strong> a approuvé le projet.",
  "timelineStakeholderGroupApprovalTextApprovedBy": "<strong>{{approvedBy}}</strong> a approuvé le projet pour <strong>{{stakeholderGroupName}}</strong>",
  "timelineMeetingTitle": "Réunion : {{meetingTitle}}",
  "timelineAddRemainingStakeholdersTitle": "Ajouter des parties prenantes restantes",
  "timelineAddRemainingStakeholdersText": "<div><strong>{{stakeholderGroupName}}</strong> en attente</div>",
  "timelineRemainingApprovalsTitle": "Approbations restantes",
  "timelineRemainingApprovalsText": "<div><strong>{{stakeholderGroupName}}</strong> en attente</div>",
  "timelineProjectKickoffTitle": "Lancement du projet",
  "timelineNextStepsTitle": "Prochaines étapes",
  "timelineNewEventPopupTitle": "Saisir une réunion",
  "timelineNewEventFormTitle": "Nom de la réunion",
  "timelineNewEventFormDate": "Date",
  "timelineNewEventFormRelevantContent": "Contenu pertinent",
  "timelineNewEventFormRelevantContentSubtitle": "Ajoutez des contenus pertinents. De nouveaux documents seront automatiquement ajoutés aux documents du projet.",
  "timelineNewEventFormDescription": "Description",
  "timelineNewEventFormCreateButton": "Créer",
  "timelineNewEventFormUpdateButton": "Mettre à jour",
  "timelineLinkToEventCopied": "Lien vers l'événement copié",
  "timelineParticipants": "Participants",
  "timelineNewEventSearchParticipants": "Rechercher",
  "timelineEditMeetingActionText": "Modifier",
  "timelineLater": "Plus tard",
  "contentInputNoDocuments": "Aucun document disponible",
  "timelineLogMilestone": "Ajouter un jalon",
  "timelineNewMilestonePopupTitle": "Ajouter un jalon",
  "timelineNewMilestoneFormTitle": "Nom du jalon",
  "timelineMilestoneTitle": "Étape : {{milestoneTitle}}",
  "timelineNewMilestoneFormDate": "Date",
  "timelineNewMilestoneFormRelevantContent": "Contenu pertinent",
  "timelineNewMilestoneFormRelevantContentSubtitle": "Ajoutez du contenu pertinent. De nouveaux documents seront automatiquement ajoutés aux documents du projet.",
  "timelineNewMilestoneFormDescription": "Description",
  "timelineNewMilestoneFormDescriptionSubtitle": "Ajoutez une description pour expliquer le jalon. Une fois le jalon atteint, vous pouvez également saisir ici un résumé de ce qui a été discuté.",
  "timelineNewMilestoneFormCreateButton": "Créer",
  "timelineNewMilestoneFormUpdateButton": "Mettre à jour",
  "timelineEditMilestoneActionText": "Modifier",
  "timelineEditMilestonePopupTitle": "Mettre à jour",
  "timelineFinishMilestoneActionText": "Terminer",
  "finishMilestone": "Terminer {{milestone}}",
  "finishMilestoneActionText": "Finaliser",
  "defaultBenefitsGroup": "Standard",
  "benefitsEditText": "Modifier les avantages pour :",
  "copyRoomLink": "Copier le lien de la salle:",
  "yourOptions": "Vos options :",
  "viewingAs": "Visualisation en tant que <strong>{{stakeholderGroupName}}</strong>",
  "documentTableEditVisibility": "Modifier la visibilité",
  "documentTableVisibility": "Visible pour :"
}
