export default {
  "projectStatus": "Projektstatus: {{percentage}}%",
  "benefitsIntro": "Darum ist {{name}} der richtige Partner:",
  "testimonials": "Diese Unternehmen vertrauen {{name}} bereits:",
  "readMore": "Erfahre mehr über {{name}}",
  "addNewSection": "Neue Sektion hinzufügen",
  "implementationCountdown": "Implementierung in {{daysLeft}} Tagen",
  "dueDate": "Stichtag:",
  "addStakeholder": "Stakeholder hinzufügen",
  "projectStakeholders": "Stakeholder",
  "projectStakeholdersSubtitle": "Hier kannst du alle Stakeholdergruppen managen, die für dieses Projekt relevant sind. Du kannst jederzeit neue Stakeholdergruppen oder einzelne Stakeholder hinzufügen um ihnen Zugriff zu diesem Projekt zu gewähren",
  "addStakeholderPopoverTitle": "Stakeholder für Gruppe {{group}} hinzufügen",
  "addStakeholderPopoverSubtitle": "Stakeholder erhalten keine automatische E-Mail",
  "addStakeholderPopoverConfirmationText": "Ich bestätige, dass das Hinzufügen dieses Stakeholders im berechtigten Interesse des Projekts erfolgt.",
  "addStakeholderGroup": "Stakeholdergruppe hinzufügen",
  "addStakeholderGroupTitle": "Stakeholdergruppe hinzufügen",
  "editStakeholderGroupTitle": "Stakeholdergruppe bearbeiten",
  "deleteStakeholderGroupTooltip": "Es gibt noch {{count}} Stakeholder in dieser Gruppe",
  "impersonate": "Als Gruppe ausgeben",
  "name": "Name",
  "save": "Speichern",
  "delete": "Löschen",
  "approve": "Genehmigen",
  "approveAsGroup": "Als Gruppe genehmigen",
  "approved": "Genehmigt",
  "projectDocumentsTitle": "Dokumente",
  "projectDocumentsTextChampion": "Du kannst hier alle geteilten Dokumente einsehen. Für deine Kollegen und Kolleginnen aus den Fachbereichen gehen wir einen Schritt weiter und zeigen nur Dokumente, die für sie relevant sind.",
  "projectDocumentsTextStakeholder": "Hier findest du alle Dokumente, die {{company}} für dich bereitgestellt hat, damit  du das Projekt besser verstehen und dein Einverständnis zu geben. Schau sie dir an, wenn du Zeit hast, stell Fragen oder leite sie intern weiter.",
  "introPopoverTextChampion": "Auf dieser Seite erhälst du alle relevanten Informationen zum Projekt mit {{company}}.<br><br>Basierend auf unserer Erfahrung helfen wir dir, Projektbeteiligte aus verschiedenen Fachbereichen für die Einführung von {{company}} zu involvieren und deren Feedback einzuholen.",
  "introPopoverTitleChampion": "Hallo {{name}}!",
  "introPopoverTextStakeholderSingle": "du wurdest von {{championName}} ({{championEmail}}) eingeladen, um deine Einschätzung zur Einführung von {{product}} zu geben.<br><br>{{championFirstName}} benötigt deine Einschätzung, um die nächsten Schritte einzuleiten und einen realistischen Projektplan aufzustellen.<br><br>Die nachfolgende Seite ist speziell auf deinen Fachbereich zugeschnitten und enthält Informationen zum Projektpartner {{company}}.",
  "introPopoverTextStakeholderMultiple": "du wurdest von {{champions}} eingeladen, um deine Einschätzung zur Einführung von {{product}} zu geben.<br><br>Sie benötigen deine Einschätzung, um die nächsten Schritte einzuleiten und einen realistischen Projektplan aufzustellen.<br><br>Die nachfolgende Seite ist speziell auf deinen Fachbereich zugeschnitten und enthält Informationen zum Projektpartner {{company}}.",
  "introPopoverTitleStakeholder": "Hallo {{name}}!",
  "continue": "Weiter",
  "documentTableName": "Name",
  "documentTableUploaded": "Hochgeladen",
  "documentTableActions": "Aktionen",
  "yourContactPersonSingle": "Deine Kontaktperson:",
  "yourContactPersonMultiple": "Deine Kontaktpersonen:",
  "yourChampionSingle": "Dein Champion:",
  "yourChampionMultiple": "Deine Champions:",
  "requestAccessText": "Beantrage Zugang zu diesem Projekt, indem du deine E-Mail-Adresse eingibst. Wenn du berechtigt bist, werden wir dir einen Zugangslink zusenden.",
  "requestAccessPlaceholder": "E-Mail eingeben",
  "requestAccessOrgUserLink": "Login",
  "requestAccessSuccess": "Zugang beantragt",
  "requestAccessOrgUser": "Bitte logge dich über den regulären Zugang ein:",
  "requestAccess": "Zugang beantragen",
  "email": "E-Mail",
  "firstName": "Vorname",
  "lastName": "Nachname",
  "add": "Hinzufügen",
  "back": "Zurück",
  "send": "Absenden",
  "documents": "Dokumente",
  "stakeholderApproveProjectTitle": "Bereit, dieses Projekt zu genehmigen?",
  "stakeholderApproveProjectText": "Das Projekt-Team braucht dein OK, damit das Projekt realisiert werden kann. Wenn du deinen Genehmigungsprozess durchlaufen hast, klicke auf den Button und lass das Projekt-Team wissen, das alles passt.",
  "stakeholderApproveProjectButton": "Projekt genehmigen",
  "stakeholderApproveProjectSuccess": "Projekt genehmigt ✓",
  "stakeholderInputTitleSingle": "Hilf {{championFirstName}}!",
  "stakeholderInputTitleMultiple": "Hilf dem Projekt-Team!",
  "stakeholderInputTextSingle": "{{championFirstName}} benötigt Hilfe von dir und deinem Fachbereich, um das Projekt planen zu können. Beantworte dazu die nachfolgenden Fragen und gib {{championFirstName}} eine Einschätzung zu deiner Verfügbarkeit.",
  "stakeholderInputTextMultiple": "Das Projekt-Team benötigt Hilfe von dir und deinem Fachbereich, um das Projekt planen zu können. Beantworte dazu die nachfolgenden Fragen und gib das Projekt-Team eine Einschätzung zu deiner Verfügbarkeit.",
  "stakeholderInputThankYouText": "Danke für einen Input! Du kannst dir jederzeit wieder anschauen, was du geschrieben hast.",
  "stakeholderInputFirstQuestionTitleSingle": "Wann hast du Zeit? {{championFirstName}} arbeitet mit verschiedenen Bereichen zusammen, um eine ordentliche Projektplanung zu gewährleisten. Dafür bittet {{championFirstName}} auch um deinen Beitrag.",
  "stakeholderInputFirstQuestionTitleMultiple": "Wann hast du Zeit? Das Projekt-Team arbeitet mit verschiedenen Bereichen zusammen, um eine ordentliche Projektplanung zu gewährleisten. Dafür bittet das Projekt-Team auch um deinen Beitrag.",
  "stakeholderInputFirstQuestionPlaceholder": "Ich habe die nächsten 2 Wochen keine Kapazität und bin anschließend eine Woche im Urlaub. Danach sieht meine Verfügbarkeit besser aus. Normalerweise dauert der Prozess bei uns 3-4 Wochen und dann kann ich mein Einverständnis geben",
  "stakeholderInputSecondQuestionTitleSingle": "Fehlen dir noch Informationen oder weitere Dokumente von {{championFirstName}} oder {{sellerCompany}}? Dann lass es sie hier wissen.",
  "stakeholderInputSecondQuestionTitleMultiple": "Fehlen dir noch Informationen oder weitere Dokumente vom Projekt-Team oder {{sellerCompany}}? Dann lass es sie hier wissen.",
  "stakeholderInputSecondQuestionPlaceholder": "Ich habe aktuell keine weiteren Fragen zu diesem Projekt.",
  "sendStakeholderLinkEmailSubject": "Dein Zugangslink",
  "sendStakeholderLinkEmailPreview": "Dein Zugang für das {{sellerOrgName}} Projekt",
  "sendStakeholderLinkEmailHeading": "Dein Zugang für das <strong>{{sellerOrgName}}</strong> Projekt",
  "sendStakeholderLinkEmailHelloSentence": "Hallo {{firstName}},",
  "sendStakeholderLinkEmailCheckoutProjectSingle": "Unter folgendem Link kannst du dir das <strong>{{sellerOrgName}}</strong> Projekt von <strong>{{championFirstName}}</strong> ansehen.",
  "sendStakeholderLinkEmailCheckoutProjectMultiple": "Unter folgendem Link kannst du dir das <strong>{{sellerOrgName}}</strong> Projekt ansehen.",
  "sendStakeholderLinkEmailFooterDisclaimer": "Diese Einladung ist nur für <span className='text-black'>{{email}}</span> vorgesehen. Bitte leite sie nicht weiter.",
  "sendStakeholderLinkEmailOrCopyPasteUrl": "oder kopiere und füge diese URL in deinen Browser ein: ",
  "sendStakeholderLinkEmailShowProject": "Projekt anzeigen",
  "sendChampionLinkEmailSubject": "Dein Zugangslink",
  "sendChampionLinkEmailPreview": "Dein Zugang für das {{sellerOrgName}} Projekt",
  "sendChampionLinkEmailHeading": "Dein Zugang für das <strong>{{sellerOrgName}}</strong> Projekt",
  "sendChampionLinkEmailFooterDisclaimer": "Diese Einladung ist nur für <span className='text-black'>{{email}}</span> vorgesehen. Bitte leite sie nicht weiter.",
  "sendChampionLinkEmailOrCopyPasteUrl": "oder kopiere und füge diese URL in deinen Browser ein: ",
  "sendChampionLinkEmailShowProject": "Projekt anzeigen",
  "sendChampionLinkEmailHelloSentence": "Hallo {{firstName}},",
  "sendChampionLinkEmailCheckoutProject": "Unter folgendem Link kannst du dir das <strong>{{sellerOrgName}}</strong> Projekt ansehen.",
  "inviteCodeExpired": "Der Einladungscode ist abgelaufen. Bitte gib deine E-Mail-Adresse erneut ein.",
  "sendLink": "Link senden",
  "joinSiteDesktopExplanation": "Bitte sie dir diese Website auf einem Desktop-PC oder einem größeren Bildschirm an, um sie optimal nutzen zu können.",
  "joinSiteDesktopSendLink": "Gib deine E-Mail-Adresse ein und wir senden dir einen Link zu dieser Seite an deinen Posteingang.",
  "requestLinkSuccess": "Link gesendet",
  "timelineHeading": "Timeline",
  "timelineHeadlineSubtitle": "Erhalte einen Überblick über gemeinsame Meilensteine und Events",
  "timelineLogMeeting": "Meeting erfassen",
  "timelineFirstDemoTitle": "Erste Demo",
  "timelineFirstDemoText": "Demo von {{productName}} für {{customerName}}",
  "timelineProjectPageCreatedTitle": "Projektseite erstellt",
  "timelineContactPersonAddedTitle": "Kontaktperson <strong>{{contactPersonName}}</strong> hinzugefügt zur Gruppe <strong>{{contactPersonGroupName}}</strong>",
  "timelineStakeholderAddedTitle": "Stakeholder <strong>{{stakeholderName}}</strong> hinzugefügt zur Gruppe <strong>{{stakeholderGroupName}}</strong>",
  "timelineStakeholderGroupApprovalTitle": "Genehmigung erteilt",
  "timelineStakeholderGroupApprovalTextNoApprovedBy": "<strong>{{stakeholderGroupName}}</strong> hat das Projekt genehmigt",
  "timelineStakeholderGroupApprovalTextApprovedBy": "<strong>{{approvedBy}}</strong> hat das Projekt für <strong>{{stakeholderGroupName}}</strong> genehmigt",
  "timelineMeetingTitle": "Meeting: {{meetingTitle}}",
  "timelineAddRemainingStakeholdersTitle": "Stakeholder hinzufügen", // plural
  "timelineAddRemainingStakeholdersText": "<div><strong>{{stakeholderGroupName}}</strong> ausstehend</div>",
  "timelineRemainingApprovalsTitle": "Ausstehende Genehmigungen",
  "timelineRemainingApprovalsText": "<div><strong>{{stakeholderGroupName}}</strong> ausstehend</div>",
  "timelineProjectKickoffTitle": "Projektstart",
  "timelineNextStepsTitle": "Nächste Schritte",
  "timelineNewEventPopupTitle": "Meeting erfassen",
  "timelineNewEventFormTitle": "Meeting Name",
  "timelineNewEventFormDate": "Datum",
  "timelineNewEventFormRelevantContent": "Relevante Inhalte",
  "timelineNewEventFormRelevantContentSubtitle": "Füge relevante Inhalte hinzu. Neue Dokumente werden automatisch den Projektdokumenten hinzugefügt.",
  "timelineNewEventFormDescription": "Beschreibung",
  "timelineNewEventFormCreateButton": "Erstellen",
  "timelineNewEventFormUpdateButton": "Aktualisieren",
  "timelineLinkToEventCopied": "Link zum Event kopiert",
  "timelineParticipants": "Teilnehmer",
  "timelineNewEventSearchParticipants": "Suchen",
  "timelineEditMeetingActionText": "Bearbeiten",
  "timelineLater": "Später",
  "contentInputNoDocuments": "Keine Dokumente vorhanden",
  "timelineLogMilestone": "Meilenstein hinzufügen",
  "timelineNewMilestonePopupTitle": "Meilenstein hinzufügen",
  "timelineNewMilestoneFormTitle": "Meilenstein Name",
  "timelineMilestoneTitle": "Meilenstein: {{milestoneTitle}}",
  "timelineNewMilestoneFormDate": "Datum",
  "timelineNewMilestoneFormRelevantContent": "Relevante Inhalte",
  "timelineNewMilestoneFormRelevantContentSubtitle": "Füge relevante Inhalte hinzu. Neue Dokumente werden automatisch den Projektdokumenten hinzugefügt.",
  "timelineNewMilestoneFormDescription": "Beschreibung",
  "timelineNewMilestoneFormDescriptionSubtitle": "Füge eine Beschreibung hinzu, um den Meilenstein zu erklären. Wenn er Meilenstein erreicht ist, kannst du hier auch eine Zusammenfassung des besprocheneen eingeben.",
  "timelineNewMilestoneFormCreateButton": "Erstellen",
  "timelineNewMilestoneFormUpdateButton": "Aktualisieren",
  "timelineEditMilestoneActionText": "Bearbeiten",
  "timelineEditMilestonePopupTitle": "Aktualisieren",
  "timelineFinishMilestoneActionText": "Abschließen",
  "finishMilestone": "{{milestone}} abschließen",
  "finishMilestoneActionText": "Abschließen",
  "defaultBenefitsGroup": "Default",
  "benefitsEditText": "Bearbeite die Vorteile für:",
  "copyRoomLink": "Room-Link kopieren:",
  "yourOptions": "Deine Optionen:",
  "viewingAs": "Betrachtung als <strong>{{stakeholderGroupName}}</strong>",
  "documentTableEditVisibility": "Sichtbarkeit bearbeiten",
  "documentTableVisibility": "Sichtbar für:"
}
