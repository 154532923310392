export default {
  "signUpHeading": "Create an account",
  "signUp": "Sign Up",
  "signUpTerms": "By signing up, you agree to our <a href='https://dealday.io/terms' target='_blank' class='underline' rel='noreferrer'>Terms of Service</a> and <a href='https://dealday.io/privacy' target='_blank' class='underline' rel='noreferrer'>Privacy Policy</a>",
  "signInHeading": "Sign in to your account",
  "signIn": "Sign In",
  "getStarted": "Get started",
  "signOut": "Sign out",
  "signingIn": "Signing in...",
  "signingUp": "Signing up...",
  "orContinueWithEmail": "or continue with email",
  "doNotHaveAccountYet": "Do not have an account yet?",
  "alreadyHaveAnAccount": "Already have an account?",
  "joinOrganizationHeading": "Join {{organization}}",
  "joinOrganizationSubHeading": "You were invited to join <b>{{organization}}</Bold>",
  "signUpToAcceptInvite": "Please sign in/up to accept the invite",
  "clickToAcceptAs": "Click the button below to accept the invite with as <b>{{email}}</b>",
  "acceptInvite": "Accept invite",
  "acceptingInvite": "Accepting Invite...",
  "acceptInviteSuccess": "Invite successfully accepted",
  "acceptInviteError": "Error encountered while accepting invite",
  "acceptInviteWithDifferentAccount": "Want to accept the invite with a different account?",
  "alreadyHaveAccountStatement": "I already have an account, I want to sign in instead",
  "doNotHaveAccountStatement": "I do not have an account, I want to sign up instead",
  "addingToOrganization": "We are adding you to <b>{{name}}</b>. Please Wait...",
  "signInWithProvider": "Sign in with {{provider}}",
  "signInWithPhoneNumber": "Sign in with Phone Number",
  "passwordHint": "Ensure it's at least 6 characters",
  "repeatPasswordHint": "Type your password again",
  "repeatPassword": "Repeat password",
  "passwordsDoNotMatch": "The passwords do not match",
  "passwordForgottenQuestion": "Password forgotten?",
  "passwordResetLabel": "Reset Password",
  "passwordResetSubheading": "Enter your email address below. You will receive a link to reset your password.",
  "passwordResetSuccessMessage": "Check your Inbox! We emailed you a link for resetting your Password.",
  "passwordRecoveredQuestion": "Password recovered?",
  "passwordLengthError": "Please provide a password with at least 6 characters",
  "sendEmailLink": "Send Email Link",
  "sendingEmailLink": "Sending Email Link...",
  "sendLinkSuccess": "We sent you a link to your email! Follow the link to sign in.",
  "sendLinkSuccessToast": "Link successfully sent",
  "getNewLink": "Get a new link",
  "verificationCode": "Verification Code",
  "verificationCodeHint": "Enter the code we sent you by SMS",
  "verificationCodeSubmitButtonLabel": "Submit Verification Code",
  "reauthenticate": "Reauthenticate",
  "reauthenticateDescription": "For security reasons, we need you to re-authenticate",
  "errorAlertHeading": "Sorry, we could not authenticate you",
  "emailConfirmationAlertHeading": "We sent you a confirmation email.",
  "emailConfirmationAlertBody": "Welcome! Please check your email and click the link to verify your account.",
  "resendLink": "Resend link",
  "resendLinkSuccess": "We sent you a new link to your email! Follow the link to sign in.",
  "authenticationErrorAlertHeading": "Authentication Error",
  "authenticationErrorAlertBody": "Sorry, we could not authenticate you. Please try again.",
  "errors": {
    "Invalid login credentials": "The credentials entered are invalid",
    "User already registered": "This credential is already in use. Please try with another one.",
    "Email not confirmed": "Please confirm your email address before signing in",
    "default": "We have encountered an error. Please ensure you have a working internet connection and try again",
    "generic": "Sorry, we weren't able to authenticate you. Please try again.",
    "link": "Sorry, we encountered an error while sending your link. Please try again.",
    "codeVerifierMismatch": "It looks like you're trying to sign in using a different browser than the one you used to request the sign in link. Please try again using the same browser."
  }
}
