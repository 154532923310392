import enTranslations from "app/locales/en";
import deTranslations from "app/locales/de";
import frTranslations from "app/locales/fr";

const DEFAULT_LOCALE = 'en';

const NAMESPACES = [
  'common',
  'auth',
  'organization',
  'profile',
  'subscription',
  'onboarding',
  'projectPage'
] as const;

const i18Config = {
  fallbackLanguage: DEFAULT_LOCALE,
  supportedLanguages: [DEFAULT_LOCALE, 'de', 'fr'],
  defaultNS: NAMESPACES,
  react: { useSuspense: false },
  resources: {
    en: enTranslations,
    de: deTranslations,
    fr: frTranslations
  }
};

export default i18Config;
