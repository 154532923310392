import auth from "./auth";
import common from "./common";
import onboarding from "./onboarding";
import organization from "./organization";
import profile from "./profile";
import projectPage from "./projectPage";
import subscription from "./subscription";

export default {
  auth: auth,
  common: common,
  onboarding: onboarding,
  organization: organization,
  profile: profile,
  projectPage: projectPage,
  subscription: subscription
}