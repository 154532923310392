export default {
  "dashboardTabLabel": "Dashboard",
  "projectsTabLabel": "Rooms",
  "snippetsTabLabel": "Snippets",
  "productsTabLabel": "Produkte",
  "toolsTabLabel": "Tools",
  "addNewProject": "Neuer Room",
  "upgradeToCreateProject": "Upgrade um Rooms zu erstellen",
  "addNewProduct": "Neues Produkt",
  "upgradeToCreateProduct": "Upgrade um Produkte zu erstellen",
  "addNewSnippet": "Neues Snippet",
  "organizationSettingsTabLabel": "Organization",
  "settingsTabLabel": "Settings",
  "profileSettingsTabLabel": "Profile",
  "subscriptionSettingsTabLabel": "Subscription",
  "dashboardTabDescription": "An overview of your organization's activity and performance across all your projects.",
  "settingsTabDescription": "Manage your settings and preferences.",
  "emailAddress": "Email Address",
  "password": "Password",
  "modalConfirmationQuestion": "Are you sure you want to continue?",
  "imageInputLabel": "Click here to upload an image",
  "cancel": "Cancel",
  "backToHomePage": "Back to Home Page",
  "genericServerError": "Sorry, something went wrong.",
  "genericServerErrorHeading": "Sorry, something went wrong while processing your request. Please contact us if the issue persists.",
  "pageNotFound": "Ops. Page not Found.",
  "pageNotFoundSubHeading": "Apologies, the page you were looking for was not found",
  "genericError": "Oooops. An error occurred",
  "anonymousUser": "Anonymous",
  "theme": "Theme",
  "lightTheme": "Light",
  "darkTheme": "Dark",
  "systemTheme": "System",
  "expandSidebar": "Expand Sidebar",
  "collapseSidebar": "Collapse Sidebar",
  "documentation": "Documentation",
  "getStarted": "Get Started",
  "retry": "Retry",
  "contactUs": "Contact Us",
  "loading": "Loading",
  "yourOrganizations": "Your Organizations",
  "continue": "Continue",
  "skip": "Skip",
  "roles": {
    "owner": {
      "label": "Owner",
      "description": "Can change any setting, invite new members and manage billing"
    },
    "admin": {
      "label": "Admin",
      "description": "Can change some settings, invite members, perform disruptive actions"
    },
    "member": {
      "label": "Member",
      "description": "Cannot invite members or change settings"
    },
    "viewer": {
      "label": "Viewer",
      "description": "Can view all projects and products, but cannot make any changes. Does not count towards billing."
    }
  },
  "noProjectsYet": "No projects yet",
  "noProductsYet": "No products yet",
  "createProject": "Create Room",
  "createTool": "Create Tool"
}
