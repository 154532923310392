import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import initializeClientI18n from './i18n/initialize-client-i18n';
import { loadSelectedTheme } from '~/core/theming';
import configuration from '~/configuration';
import clearBrowserExtensionInjectionsBeforeHydration from '~/core/generic/fix-hydration';
import initializeBrowserSentry from './core/sentry/initialize-browser-sentry';

initializeBrowserSentry();

const hydrate = () => {
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    initializeClientI18n().then((i18next) => {
      const App = (
        <StrictMode>
          <I18nextProvider i18n={i18next}>
              <RemixBrowser />
          </I18nextProvider>
        </StrictMode>
      );

      hydrateRoot(document, App);
    });
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

if (configuration.features.enableThemeSwitcher) {
  loadSelectedTheme();
}