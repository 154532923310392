export default {
  "subscriptionTabSubheading": "Manage your Subscription and Billing",
  "checkout": "Checkout",
  "manageBillingDescription": "Visit your Customer Portal to manage your subscription and billing.",
  "manageBilling": "Go to Customer Portal",
  "notSubscribedToAnyPlan": "You are not currently subscribed to any plan",
  "choosePlan": "Select a plan below",
  "currentPlan": "Your Plan",
  "subscriptionWillEndOn": "The subscription will end on {{ endDate }}",
  "unknownErrorAlertHeading": "Sorry, something went wrong",
  "unknownErrorAlert": "We encountered an unknown error while processing your payment. Please try again or contact support.",
  "checkOutCanceledAlertHeading": "The checkout was canceled",
  "checkOutCanceledAlert": "The checkout was canceled. Please contact us if you're experiencing any issues.",
  "checkOutCompletedAlertHeading": "Checkout successfully completed",
  "checkOutCompletedAlert": "Yay, your payment went through!",
  "cancelAtPeriodEndDescription": "Your subscription is scheduled to be canceled on {{ endDate }}.",
  "renewAtPeriodEndDescription": "Your subscription is scheduled to be renewed on {{ renewDate }}",
  "noPermissionsAlertHeading": "You don't have permissions to change the billing",
  "noPermissionsAlertBody": "Please contact your organization owner to change the billing settings for your organization.",
  "updatePlanModalHeading": "Switch to a different plan",
  "updatePlanModalSubheading": "Choose a plan that suits your needs.",
  "switchPlan": "Switch Plan",
  "switchToPlan": "Switch to plan",
  "confirmSwitchPlan": "Click again to confirm",
  "resumeSubscription": "Resume Subscription",
  "resumeSubscriptionBody": "By resuming your subscription, you will regain your plan benefits",
  "confirmResumeSubscription": " Yes, Resume Subscription",
  "cancelSubscription": "Cancel Subscription",
  "cancelSubscriptionBody": "By unsubscribing, you will lose your plan benefits",
  "confirmCancelSubscription": " Yes, Cancel Subscription",
  "updatePaymentMethod": "Update Payment Method",
  "status": {
    "free": {
      "label": "Free Plan",
      "heading": "You are currently on the Free Plan",
      "description": "You're on a free plan. You can upgrade to a paid plan at any time."
    },
    "active": {
      "label": "Active",
      "heading": "Your subscription is active",
      "description": "Your subscription is active. You can manage your subscription and billing in the Customer Portal."
    },
    "on_trial": {
      "label": "Trial",
      "heading": "You're on a trial",
      "description": "Your trial will end on {{ trialEndDate }}."
    },
    "past_due": {
      "label": "Past Due",
      "heading": "Your invoice is past due",
      "description": "Your invoice is past due. Please update your payment method."
    },
    "cancelled": {
      "label": "Canceled",
      "heading": "Your subscription is canceled",
      "description": "Your subscription is canceled. It is scheduled to end on {{ endDate }}."
    },
    "unpaid": {
      "label": "Unpaid",
      "heading": "Your invoice is unpaid",
      "description": "Your invoice is unpaid. Please update your payment method."
    },
    "incomplete": {
      "label": "Incomplete",
      "heading": "We're waiting for your payment",
      "description": "We're waiting for your payment to go through. Please bear with us."
    },
    "expired": {
      "label": "Expired",
      "heading": "Your payment has expired",
      "description": "Your payment has expired. Please update your payment method."
    },
    "paused": {
      "label": "Paused",
      "heading": "Your subscription is paused",
      "description": "Your subscription is paused. You can resume it at any time."
    }
  }
}