import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import i18n from './i18next.config';
import { getInitialNamespaces } from 'remix-i18next/client';

async function initializeClientI18n() {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18n,
      fallbackLng: i18n.fallbackLanguage,
      supportedLngs: i18n.supportedLanguages,
      ns: [...i18n.defaultNS, ...getInitialNamespaces()],
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    });

  return i18next;
}

export default initializeClientI18n;
