export default {
  "generalTabLabel": "General",
  "generalTabLabelSubheading": "Manage your Organization",
  "membersTabLabel": "Members",
  "featureFlagsTabLabel": "Fonctionnalités expérimentales",
  "featureFlagsTabSubheading": "Gérez les fonctionnalités de cette organisation (réservé aux administrateurs globaux)",
  "integrationsTabLabel": "Intégrations",
  "integrationsTabSubheading": "Gérez les intégrations de votre organisation",
  "emailSettingsTab": "Email",
  "membersTabSubheading": "Manage and Invite members",
  "inviteMembersPageSubheading": "Invite members to your organization",
  "createOrganizationModalHeading": "Create Organization",
  "organizationNameLabel": "Organization Name",
  "createOrganizationSubmitLabel": "Create Organization",
  "createOrganizationSuccess": "Organization created successfully",
  "createOrganizationError": "Organization not created. Please try again.",
  "createOrganizationLoading": "Creating organization...",
  "settingsPageLabel": "General",
  "createOrganizationDropdownLabel": "New organization",
  "changeRole": "Change Role",
  "removeMember": "Remove",
  "inviteMembersSuccess": "Members invited successfully!",
  "inviteMembersError": "Sorry, we encountered an error! Please try again",
  "inviteMembersLoading": "Inviting members...",
  "removeInviteButtonLabel": "Remove invite",
  "addAnotherMemberButtonLabel": "Add another one",
  "inviteMembersSubmitLabel": "Send Invites",
  "removeMemberModalHeading": "You are removing this user",
  "removeMemberSuccessMessage": "Member removed successfully",
  "removeMemberErrorMessage": "Sorry, we encountered an error. Please try again",
  "removeMemberLoadingMessage": "Removing member...",
  "removeMemberSubmitLabel": "Remove User from Organization",
  "chooseDifferentRoleError": "Role is the same as the current one",
  "updateRoleLoadingMessage": "Updating role...",
  "updateRoleSuccessMessage": "Role updated successfully",
  "updatingRoleErrorMessage": "Sorry, we encountered an error. Please try again.",
  "updateMemberRoleModalHeading": "Update Member's Role",
  "memberRoleInputLabel": "Member role",
  "updateRoleSubmitLabel": "Update Role",
  "transferOwnership": "Transfer Ownership",
  "deleteInviteModalHeading": "Deleting Invite",
  "deleteInviteSuccessMessage": "Invite deleted successfully",
  "deleteInviteErrorMessage": "Invite not deleted. Please try again.",
  "deleteInviteLoadingMessage": "Deleting invite. Please wait...",
  "confirmDeletingMemberInvite": "You are deleting the invite to <b>{{ email }}</b>",
  "transferOwnershipDisclaimer": "You are transferring ownership of the selected organization to <b>{{ member }}</b>. Your new role will be <b>$t(common:roles.admin.label)</b>.",
  "transferringOwnership": "Transferring ownership...",
  "transferOwnershipSuccess": "Ownership successfully transferred",
  "transferOwnershipError": "Sorry, we could not transfer ownership to the selected member. Please try again.",
  "deleteInviteSubmitLabel": "Delete Invite",
  "youBadgeLabel": "You",
  "updateOrganizationLoadingMessage": "Updating Organization...",
  "updateOrganizationSuccessMessage": "Organization successfully updated",
  "updateOrganizationErrorMessage": "Could not update Organization. Please try again.",
  "updateLogoErrorMessage": "Could not update Logo. Please try again.",
  "organizationNameInputLabel": "Organization Name",
  "organizationLogoInputLabel": "Organization Logo",
  "updateOrganizationSubmitLabel": "Update Organization",
  "inviteMembersPageHeading": "Invite Members",
  "goBackToMembersPage": "Go back to members",
  "membersPageHeading": "Members",
  "inviteMembersButtonLabel": "Invite Members",
  "pendingInvitesHeading": "Pending Invites",
  "pendingInvitesSubheading": "Manage invites not yet accepted",
  "noPendingInvites": "No pending invites found",
  "loadingMembers": "Loading members...",
  "loadMembersError": "Sorry, we couldn't fetch your organization's members.",
  "loadInvitedMembersError": "Sorry, we couldn't fetch your organization's invited members.",
  "loadingInvitedMembers": "Loading invited members...",
  "invitedBadge": "Invited",
  "duplicateInviteEmailError": "You have already entered this email address",
  "invitingOwnAccountError": "Hey, that's your email!",
  "dangerZone": "Danger Zone",
  "dangerZoneSubheading": "Delete or leave your organization",
  "deleteOrganization": "Delete Organization",
  "deleteOrganizationDescription": "This action cannot be undone. All data associated with this organization will be deleted.",
  "deletingOrganization": "Deleting organization",
  "deleteOrganizationModalHeading": "Deleting Organization",
  "deleteOrganizationInputField": "Type the name of the organization to confirm",
  "leaveOrganization": "Leave Organization",
  "leavingOrganizationModalHeading": "Leaving Organization",
  "leaveOrganizationDescription": "You will no longer have access to this organization.",
  "deleteOrganizationDisclaimer": "You are deleting the organization {{ organizationName }}. This action cannot be undone.",
  "leaveOrganizationDisclaimer": "You are leaving the organization {{ organizationName }}. You will no longer have access to it.",
  "deleteOrganizationErrorHeading": "Sorry, we couldn't delete your organization.",
  "leaveOrganizationErrorHeading": "Sorry, we couldn't leave your organization."
}