export default {
  "projectStatus": "Project Status: {{percentage}}%",
  "benefitsIntro": "That's why {{name}} is the right partner:",
  "testimonials": "These companies already trust {{name}}:",
  "readMore": "Learn more about {{name}}",
  "addNewSection": "Add new section",
  "implementationCountdown": "Implementation in {{daysLeft}} days",
  "dueDate": "Due date:",
  "addStakeholder": "Add stakeholder",
  "projectStakeholders": "Stakeholders",
  "projectStakeholdersSubtitle": "Here you can manage all stakeholder groups that are relevant to this project. You can add new stakeholder groups or individual stakeholders at any time.",
  "addStakeholderPopoverTitle": "Add stakeholder to group {{group}}",
  "addStakeholderPopoverSubtitle": "Stakeholders do not receive automatic emails",
  "addStakeholderPopoverConfirmationText": "I confirm that adding this stakeholder is in the legitimate interest of the project.",
  "addStakeholderGroup": "Add Stakeholder Group",
  "addStakeholderGroupTitle": "Add Stakeholder Group",
  "editStakeholderGroupTitle": "Edit stakeholder group",
  "deleteStakeholderGroupTooltip": "There are still {{count}} stakeholders in this group.",
  "impersonate": "Impersonate",
  "name": "Name",
  "save": "Save",
  "delete": "Delete",
  "approve": "Approve",
  "approveAsGroup": "Approve as group",
  "approved": "Approved",
  "projectDocumentsTitle": "Documents",
  "projectDocumentsTextChampion": "You can view all shared documents here. For your colleagues from the specialist departments, we take it a step further and only show documents that are relevant to them.",
  "projectDocumentsTextStakeholder": "Here you will find all the documents that {{company}} has provided for you to better understand the project and provide your consent. Take a look at them when you have time, ask questions, or share them internally.",
  "introPopoverTextChampion": "On this page, you will receive all relevant information about the project with {{company}}.<br><br>Based on our experience, we help you involve project stakeholders from different departments in the implementation of {{company}} and gather their feedback.",
  "introPopoverTitleChampion": "Hello {{name}}!",
  "introPopoverTextStakeholderSingle": "You have been invited by {{championName}} ({{championEmail}}) to provide your assessment on the introduction of {{product}}.<br><br>{{championFirstName}} needs your assessment to initiate the next steps and establish a realistic project plan.<br><br>The following page is tailored to your field of expertise and contains information about the project partner {{company}}.",
  "introPopoverTextStakeholderMultiple": "You have been invited by {{champions}} to provide your assessment on the implementation of {{product}}.<br><br>They need your assessment to initiate the next steps and create a realistic project plan.<br><br>The following page is specifically tailored to your area of expertise and contains information about the project partner {{company}}.",
  "introPopoverTitleStakeholder": "Hello {{name}}!",
  "continue": "Continue",
  "documentTableName": "Name",
  "documentTableUploaded": "Uploaded",
  "documentTableActions": "Actions",
  "yourContactPersonSingle": "Your contact person:",
  "yourContactPersonMultiple": "Your contact persons:",
  "yourChampionSingle": "Your champion:",
  "yourChampionMultiple": "Your champions:",
  "requestAccessText": "Request access to this project by entering your email address. If you are eligible, we will send you an access link.",
  "requestAccessPlaceholder": "Enter Email",
  "requestAccessOrgUserLink": "Login",
  "requestAccessSuccess": "Access requested",
  "requestAccessOrgUser": "Please log in using the regular access:",
  "requestAccess": "Request access",
  "email": "Email",
  "firstName": "First Name",
  "lastName": "Last Name",
  "add": "Add",
  "back": "Back",
  "send": "Submit",
  "documents": "Documents",
  "stakeholderApproveProjectTitle": "Ready to approve this project?",
  "stakeholderApproveProjectText": "The project team needs your approval for the project to be realized. Once you have gone through your approval process, click the button and let the project team know that everything is in order.",
  "stakeholderApproveProjectButton": "Approve Project",
  "stakeholderApproveProjectSuccess": "Project approved ✓",
  "stakeholderInputTitleSingle": "Help {{championFirstName}}!",
  "stakeholderInputTitleMultiple": "Help the project team!",
  "stakeholderInputTextSingle": "{{championFirstName}} needs help from you and your department to plan the project. Please answer the following questions and provide {{championFirstName}} with an assessment of your availability.",
  "stakeholderInputTextMultiple": "The project team needs help from you and your department to plan the project. Please answer the following questions and give the project team an assessment of your availability.",
  "stakeholderInputThankYouText": "Thank you for your input! You can revisit what you wrote at any time.",
  "stakeholderInputFirstQuestionTitleSingle": "When do you have time? {{championFirstName}} is working with different departments to ensure proper project planning. For this, {{championFirstName}} also asks for your contribution.",
  "stakeholderInputFirstQuestionTitleMultiple": "When do you have time? The project team collaborates with various departments to ensure proper project planning. For this, the project team also requests your input.",
  "stakeholderInputFirstQuestionPlaceholder": "I have no capacity for the next 2 weeks and will be on vacation for one week afterwards. After that, my availability looks better. Usually, the process takes 3-4 weeks for us, and then I can give my consent.",
  "stakeholderInputSecondQuestionTitleSingle": "Do you need any additional information or documents from {{championFirstName}} or {{sellerCompany}}? If so, let them know here.",
  "stakeholderInputSecondQuestionTitleMultiple": "Do you still need information or additional documents from the project team or {{sellerCompany}}? Then let them know here.",
  "stakeholderInputSecondQuestionPlaceholder": "I currently have no further questions about this project.",
  "sendStakeholderLinkEmailSubject": "Your access link",
  "sendStakeholderLinkEmailPreview": "Your access to the {{sellerOrgName}} project",
  "sendStakeholderLinkEmailHeading": "Your access for the <strong>{{sellerOrgName}}</strong> project",
  "sendStakeholderLinkEmailHelloSentence": "Hello {{firstName}},",
  "sendStakeholderLinkEmailCheckoutProjectSingle": "You can view the <strong>{{sellerOrgName}}</strong> project from <strong>{{championFirstName}}</strong> at the following link.",
  "sendStakeholderLinkEmailCheckoutProjectMultiple": "You can view the <strong>{{sellerOrgName}}</strong> project at the following link.",
  "sendStakeholderLinkEmailFooterDisclaimer": "This invitation is intended for <span className='text-black'>{{email}}</span> only. Please do not forward it.",
  "sendStakeholderLinkEmailOrCopyPasteUrl": "or copy and paste this URL into your browser:",
  "sendStakeholderLinkEmailShowProject": "Show Project",
  "sendChampionLinkEmailSubject": "Your access link",
  "sendChampionLinkEmailPreview": "Your access to the {{sellerOrgName}} project",
  "sendChampionLinkEmailHeading": "Your access for the <strong>{{sellerOrgName}}</strong> project",
  "sendChampionLinkEmailFooterDisclaimer": "This invitation is intended for <span className='text-black'>{{email}}</span> only. Please do not forward it.",
  "sendChampionLinkEmailOrCopyPasteUrl": "or copy and paste this URL into your browser:",
  "sendChampionLinkEmailShowProject": "View Project",
  "sendChampionLinkEmailHelloSentence": "Hello {{firstName}},",
  "sendChampionLinkEmailCheckoutProject": "You can view the <strong>{{sellerOrgName}}</strong> project at the following link.",
  "inviteCodeExpired": "The invitation code has expired. Please re-enter your email address.",
  "sendLink": "Send link",
  "joinSiteDesktopExplanation": "Please view this website on a desktop computer or a larger screen to fully utilize it.",
  "joinSiteDesktopSendLink": "Enter your email address and we will send you a link to this page in your inbox.",
  "requestLinkSuccess": "Link sent",
  "timelineHeading": "Timeline",
  "timelineHeadlineSubtitle": "Get an overview of shared milestones and events",
  "timelineLogMeeting": "Record meeting",
  "timelineFirstDemoTitle": "First Demo",
  "timelineFirstDemoText": "Demo of {{productName}} for {{customerName}}",
  "timelineProjectPageCreatedTitle": "Project page created",
  "timelineContactPersonAddedTitle": "Contact person <strong>{{contactPersonName}}</strong> added to the group <strong>{{contactPersonGroupName}}</strong>",
  "timelineStakeholderAddedTitle": "Stakeholder <strong>{{stakeholderName}}</strong> added to the group <strong>{{stakeholderGroupName}}</strong>",
  "timelineStakeholderGroupApprovalTitle": "Approval granted",
  "timelineStakeholderGroupApprovalTextNoApprovedBy": "<strong>{{stakeholderGroupName}}</strong> has approved the project",
  "timelineStakeholderGroupApprovalTextApprovedBy": "<strong>{{approvedBy}}</strong> has approved the project for <strong>{{stakeholderGroupName}}</strong>",
  "timelineMeetingTitle": "Meeting: {{meetingTitle}}",
  "timelineAddRemainingStakeholdersTitle": "Add remaining stakeholders",
  "timelineAddRemainingStakeholdersText": "<div><strong>{{stakeholderGroupName}}</strong> outstanding</div>",
  "timelineRemainingApprovalsTitle": "Pending Approvals",
  "timelineRemainingApprovalsText": "<div><strong>{{stakeholderGroupName}}</strong> pending</div>",
  "timelineProjectKickoffTitle": "Project Kickoff",
  "timelineNextStepsTitle": "Next Steps",
  "timelineNewEventPopupTitle": "Record Meeting",
  "timelineNewEventFormTitle": "Meeting Name",
  "timelineNewEventFormDate": "Date",
  "timelineNewEventFormRelevantContent": "Relevant content",
  "timelineNewEventFormRelevantContentSubtitle": "Add relevant content. New documents will be automatically added to the project documents.",
  "timelineNewEventFormDescription": "Description",
  "timelineNewEventFormCreateButton": "Create",
  "timelineNewEventFormUpdateButton": "Update",
  "timelineLinkToEventCopied": "Link to event copied",
  "timelineParticipants": "Participants",
  "timelineNewEventSearchParticipants": "Search",
  "timelineEditMeetingActionText": "Edit",
  "timelineLater": "Later",
  "contentInputNoDocuments": "No documents available",
  "timelineLogMilestone": "Add milestone",
  "timelineNewMilestonePopupTitle": "Add Milestone",
  "timelineNewMilestoneFormTitle": "Milestone Name",
  "timelineMilestoneTitle": "Milestone: {{milestoneTitle}}",
  "timelineNewMilestoneFormDate": "Date",
  "timelineNewMilestoneFormRelevantContent": "Relevant content",
  "timelineNewMilestoneFormRelevantContentSubtitle": "Add relevant content. New documents will be automatically added to the project documents.",
  "timelineNewMilestoneFormDescription": "Description",
  "timelineNewMilestoneFormDescriptionSubtitle": "Add a description to explain the milestone. Once the milestone is reached, you can also enter a summary of what was discussed here.",
  "timelineNewMilestoneFormCreateButton": "Create",
  "timelineNewMilestoneFormUpdateButton": "Update",
  "timelineEditMilestoneActionText": "Edit",
  "timelineEditMilestonePopupTitle": "Update",
  "timelineFinishMilestoneActionText": "Complete",
  "finishMilestone": "Complete {{milestone}}",
  "finishMilestoneActionText": "Complete",
  "defaultBenefitsGroup": "Default",
  "benefitsEditText": "Edit the benefits for:",
  "copyRoomLink": "Copy room link:",
  "yourOptions": "Your options:",
  "viewingAs": "Viewing as <strong>{{stakeholderGroupName}}</strong>",
  "documentTableEditVisibility": "Edit visibility",
  "documentTableVisibility": "Visible to:"
}
