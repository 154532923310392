export default {
  "generalTab": "My Details",
  "generalTabSubheading": "Manage your profile details",
  "emailTab": "Email",
  "emailTabTabSubheading": "Update your email address",
  "passwordTab": "Password",
  "passwordTabSubheading": "Update your password",
  "manageConnectedAccounts": "Connected Accounts",
  "manageConnectedAccountsSubheading": "Manage your connected accounts",
  "connectedAccounts": "Connected Accounts",
  "connectedAccountsSubheading": "Below are the accounts linked to your profile",
  "availableProviders": "Available Providers",
  "availableProvidersSubheading": "Click on the providers below to link your profile to the provider",
  "updateProfileSuccess": "Profile successfully updated",
  "updateProfileError": "Encountered an error. Please try again",
  "updatePasswordSuccess": "Password update request successful",
  "updatePasswordSuccessMessage": "We sent you an email to confirm your new password. Please check your inbox and click on the link to confirm your new password.",
  "updatePasswordError": "Encountered an error. Please try again",
  "updatePasswordLoading": "Updating password...",
  "updateProfileLoading": "Updating profile...",
  "displayNameLabel": "Your Name",
  "emailLabel": "Email Address",
  "profilePictureLabel": "Your Photo",
  "updateProfileSubmitLabel": "Update Profile",
  "currentPassword": "Current Password",
  "newPassword": "New Password",
  "repeatPassword": "Repeat New Password",
  "yourPassword": "Your Password",
  "updatePasswordSubmitLabel": "Update Password",
  "newEmail": "Your New Email",
  "repeatEmail": "Repeat Email",
  "updateEmailSubmitLabel": "Update Email Address",
  "updateEmailSuccess": "Email update request successful",
  "updateEmailSuccessMessage": "We sent you an email to confirm your new email address. Please check your inbox and click on the link to confirm your new email address.",
  "updateEmailLoading": "Updating your email...",
  "updateEmailError": "Email not updated. Please try again",
  "passwordNotMatching": "Passwords do not match. Make sure you're using the correct password",
  "passwordNotChanged": "Your password has not changed",
  "emailsNotMatching": "Emails do not match. Make sure you're using the correct email",
  "updatingSameEmail": "The email chosen is the same as your current one",
  "cannotUpdateEmail": "You cannot update your email because your account is not linked to any.",
  "cannotUpdatePassword": "You cannot update your password because your account is not linked to any.",
  "unlinkActionLabel": "Unlink",
  "unlinkAccountModalHeading": "Unlink Account",
  "confirmUnlink": "You're about to unlink this account.",
  "confirmUnlinkSubmitLabel": "Yep, Unlink Account",
  "unlinkActionSuccess": "Account successfully unlinked",
  "unlinkActionError": "Sorry, we couldn't unlink this account",
  "unlinkActionLoading": "Unlinking account...",
  "linkActionSuccess": "Account successfully linked",
  "linkActionError": "Sorry, we couldn't link this account",
  "linkActionLoading": "Linking account...",
  "linkAccount": "Link Account",
  "connectWithProvider": "Connect with {{ provider }}",
  "connectedWithProvider": "Connected with {{ provider }}",
  "factorNameLabel": "A memorable name to identify this factor",
  "factorNameHint": "Use an easy-to-remember name to easily identify this factor in the future. Ex. iPhone 14",
  "factorNameSubmitLabel": "Set factor name",
  "unenrollTooltip": "Unenroll this factor",
  "unenrollingFactor": "Unenrolling factor...",
  "unenrollFactorSuccess": "Factor successfully unenrolled",
  "unenrollFactorError": "Unenrolling factor failed",
  "factorsListError": "Error loading factors list",
  "factorName": "Factor Name",
  "factorType": "Type",
  "factorStatus": "Status",
  "verificationCode": "Verification Code",
  "addEmailAddress": "Add Email address",
  "phoneNumberLabel": "Phone Number",
  "addPhoneNumber": "Add Phone Number",
  "removePhoneNumber": "Remove Phone Number",
  "confirmRemovePhoneNumberDescription": "You're about to remove your phone number. You will not be able to use it to login to your account.",
  "confirmRemovePhoneNumber": "Yes, remove phone number",
  "verifyActivationCodeDescription": "Enter the verification code generated by your authenticator app",
  "loadingFactors": "Loading factors...",
  "qrCodeError": "Sorry, we weren't able to generate the QR code",
  "submitVerificationCode": "Submit Verification Code",
  "unenrollFactorModalHeading": "Unenroll Factor",
  "unenrollFactorModalBody": "You're about to unenroll this factor. You will not be able to use it to login to your account.",
  "unenrollFactorModalButtonLabel": "Yes, unenroll factor",
  "selectFactor": "Choose a factor to verify your identity",

  "sendingEmailVerificationLink": "Sending Email...",
  "sendEmailVerificationLinkSuccess": "Verification link successfully sent",
  "sendEmailVerificationLinkError": "Sorry, we weren't able to send you the email",
  "sendVerificationLinkSubmitLabel": "Send Verification Link",
  "sendVerificationLinkSuccessLabel": "Email sent! Check your Inbox",
  "verifyEmailAlertHeading": "Please verify your email to enable MFA",
  "verificationLinkAlertDescription": "Your email is not yet verified. Please verify your email to be able to set up Multi-Factor Authentication.",
  "authFactorName": "Factor Name (optional)",
  "authFactorNameHint": "Assign a name that helps you remember the phone number used",
  "loadingUser": "Loading user details. Please wait...",
  "linkPhoneNumber": "Link Phone Number",
  "dangerZone": "Danger Zone",
  "dangerZoneSubheading": "Some actions cannot be undone. Please be careful.",
  "deleteAccount": "Delete your Account",
  "deleteAccountConfirmationHint": "You will be asked to confirm this action in the next step.",
  "deleteAccountDescription": "This will delete your account and the organizations you own. Furthermore, we will immediately cancel any active subscriptions. This action cannot be undone.",
  "deleteProfileConfirmationInputLabel": "Type DELETE to confirm",
  "deleteAccountErrorHeading": "Sorry, we couldn't delete your account"
}
