/**
 * @name clearBrowserExtensionInjectionsBeforeHydration
 * @description This function is used to clear browser extension injections
 * before hydration. This is needed because some browser extensions inject
 * their own scripts and styles into the DOM. This causes hydration to fail.
 * TODO: This function is a temporary fix. This needs a fix in React itself.
 */
function clearBrowserExtensionInjectionsBeforeHydration() {
  document
    .querySelectorAll(
      [
        'html > *:not(body, head)',
        'script[src*="extension://"]',
        'link[href*="extension://"]',
      ].join(', '),
    )
    .forEach((s) => {
      s.parentNode?.removeChild(s);
    });

  const $targets = {
    html: {
      $elm: document.querySelector('html')!,
      allowedAttributes: ['lang', 'dir', 'class', 'className', 'data-app-version'],
    },
    head: {
      $elm: document.querySelector('head')!,
      allowedAttributes: [''],
    },
    body: {
      $elm: document.querySelector('body')!,
      allowedAttributes: ['class'],
    },
  };

  Object.entries($targets).forEach(([targetName, target]) => {
    target.$elm.getAttributeNames().forEach((attr) => {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm.removeAttribute(attr);
      }
    });
  });
}

export default clearBrowserExtensionInjectionsBeforeHydration;
